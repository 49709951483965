import { Config } from '../../interfaces/config.interface';
import Logo from './monte_logo.png';

const config: Config = {
  name: 'Monte de Piedad',
  title: 'Actividades de voluntariado',
  logo: Logo,
  logo_width: '100px',
  has_hours: true,
  map_hide: true,
  catalogTalents: true,
  community: true,

};

export default config;
